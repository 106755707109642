<template>
  <div class="radio-text">
    <div class="radio-title" :style="{width: `${labelWidth}px`}">{{title}}</div>
    <div v-if="radio.length > 0" class="radio-content">
      <span
        v-for="item in radioData"
        :class="item.isCheck ? 'radio-item current-item' : 'radio-item'"
        :key="item.value"
        @click="changeRadio(item)"
        >{{item.label}}</span>
    </div>
    <div v-else class="radio-title">暂无数据</div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    multiple: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    defaultRadio: Array,
    radio: {
      type: Array,
      default: () => {
        return []
      }
    },
    labelWidth: {
      type: String,
      default: () => {
        return '80'
      }
    }
  },
  data () {
    return {
      radioData: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    // 初始化数据
    initData () {
      this.radioData = this.radio.map(item => {
        return {
          value: item.value,
          label: item.label,
          type: item.type,
          isCheck: this.defaultRadio.includes(item.value)
        }
      })
    },
    changeRadio (target) {
      if (this.multiple) {
        // 多选
        target.isCheck = !target.isCheck
        let _checkArray = this.radioData.filter(item => item.isCheck)
        this.$emit('changeRadio', _checkArray)
      } else {
        // 单选
        this.radioData.forEach(item => item.isCheck = false)
        target.isCheck = true
        this.$emit('changeRadio', [target.value])
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.radio-text {
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  .radio-title, .radio-item {
    font-weight: 409;
    font-style: normal;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    display: inline-block;
    white-space: nowrap;
  }
  .radio-content {
    flex: 1;
    .radio-item {
      display: inline-block;
      white-space: nowrap;
      margin-right: 30px;
      margin-bottom: 5px;
      padding: 0px 8px;
      transition: all .1s ease;
      &:hover {
        cursor: pointer;
        background-color: #2db7f5;
        color: #fff;
        transition: all .1s ease;
      }
    }
    .current-item {
      background-color: #2db7f5;
      color: #fff;
      transition: all .1s ease;
    }
  }
}
</style>
<template>
  <div class="complaint-detail common-page">
    <template v-if="componentName == 'default'">
      <header class="page-header">
        <div @click="goBack" class="header-left">
          <Icon class="back-icon" type="md-arrow-round-back" />
          <span class="back-title">返回{{params.backTitle}}</span>
        </div>
        <div class="header-title">申诉单详情</div>
      </header>
      <section v-if="allData" class="page-content">
       <div class="content-progress">
          <Steps :current="currentStep - 1" :status="stepStatus" size="small">
            <Step v-for="item in tempComplaintStatus" :title="item.label" :key="item.value"></Step>
          </Steps>
        </div>
        <div class="content-header">
          <h1 class="content-title">申诉车辆：{{`${allData.vehicleNo}(${allData.vehiclePlateColor})`}}</h1>
          <h2 class="content-sub-title">所属企业:  {{allData.unitName}}</h2>
          <h2 class="content-sub-title">申诉单状态:  {{getStatusByGovernType(1, allData.status)}}</h2>
        </div>
        <div class="govern-content">
          <div class="govern-notice">
            <div class="notice-item">
              <span class="notice-label">申诉单号:</span><span class="notice-value">{{ allData.appealId }}</span>
            </div>
            <div class="notice-item" style="display:flex;">
              <span class="notice-label">申诉证据附件:</span>
              <div v-if="allData.fileList" class="notice-value" >
                <div class="file-stys">
                  <div v-for="item in allData.fileList" class="btn-span file-sty" :key="item.id">
                  {{item.fileName}}&nbsp;
                    <Button size="small" @click="previewFile(item)" type="primary">预览</Button>&nbsp;
                    <Button @click="downLoadFile(item.url)" size="small" type="warning">下载</Button>
                  </div>
                </div>
              </div>
              <span v-else>暂无附件</span>
            </div>
            <div v-if="allData.status == 3 || allData.status == 1" class="btn-wrapper">
              <Button v-if="btnPrivilegeCode.complaint.includes('update')" @click="componentName = 'ComplaintEdit'" class="option-btn" type="primary" icon="ios-create" size="small">编辑</Button>
              <Button v-if="btnPrivilegeCode.complaint.includes('Revoke')" @click="repeal" class="option-btn" type="warning" icon="ios-undo" size="small">撤销</Button>
            </div>
          </div>
          <div class="detail-item">
            <div class="detail-item" v-for="item in detailData" :key="item.alarmType">
              <header class="item-title">违章类型: {{ item.alarmTypeRe }}</header>
              <div class="govern-notice">
                <div class="notice-item">
                  <span class="notice-label">申诉类型:</span>
                  <span class="notice-value">{{ item.alarmTypeRe }}</span>
                </div>
                <div class="notice-item">
                  <span class="notice-label">申诉条数:</span>
                  <span class="notice-value">{{ item.dataNum }}</span>
                </div>
                <div class="notice-item">
                  <span class="notice-label">申诉描述:</span>
                  <span class="notice-value">{{ item.appealDesc }}</span>
                </div>
              </div>
              <div class="item-table">
                <Table
                  :columns="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).colmuns"
                  :data="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).data"
                  :ref="'selection' + item.alarmType"
                  size="small"
                  max-height="300"
                >
                </Table>
              </div>
            </div>
          </div>

          <div class="detail-item" v-if="allData.status != 1">
            <header class="item-title">审核意见:{{ allData.auditView }}</header>
          </div>

          <div class="detail-item" v-if="allData.status != 1">
            <header class="item-title">申诉记录</header>
            <div class="item-table">
              <Table :columns="complaintSummaryColumns" :data="breakRuleVehData" size="small" max-height="300"></Table>
            </div>
          </div>
        </div>
      </section>
    </template>
    <div v-else-if="componentName == 'ComplaintEdit'">
      <ComplaintEdit v-if="componentName == 'ComplaintEdit'" :params="{targetRow: params.targetRow, backTitle: '治理', backComponentName: 'default'}" @goBack="goBack" />
    </div>
    <!-- 播放视频 -->
    <Modal :footer-hide="true" width="772" v-model="videoModal" :title="videoForm.title">
      <video v-if="isVideo" width="740" height="500" autoplay controls>
        <source :src="videoForm.url" type="video/mp4" />
        您的浏览器不支持 video 标签。
      </video>
      <img v-else style="width: 740px; height: 500px; object-fit: contain;" :src="videoForm.url">
    </Modal>
  </div>
</template>
<script>
import ComplaintEdit from '../complaint_edit/index.vue'
import axios from 'axios'
import { Base64 } from 'js-base64'
import { btnPrivilegeMixin, paginationMixin, alarmTableMixin, privilegeMixin, paramsMixin, dangerousDrivingBehavior, userInfoMixin } from '@/assets/js/mixin.js'
export default {
  mixins: [btnPrivilegeMixin, paginationMixin, alarmTableMixin, privilegeMixin, paramsMixin, dangerousDrivingBehavior, userInfoMixin],
  props: {
    params: {
      type: Object,
      default: () => {
        return {
          targetRow: null,
          backTitle: '',
          backComponentName: ''
        }
      }
    }
  },
  components: {
    ComplaintEdit,
  },
  data() {
    return {
      componentName: 'default',
      currentStep: 1,
      stepStatus: 'process',
      detailData: '',
      allData: '',
      tempComplaintStatus: [],
      complaintSummaryColumns: [
        { type: 'index', title: '序号', width: 80, align: 'center' },
        { title: '单号', key: 'operateNo', align: 'center' },
        { title: '审核时间', key: 'auditTime', align: 'center' },
        {
          title: '审核结果',
          key: 'auditResult',
          align: 'center',
          render: (h, params) => {
            if (params.row.auditResult == 1) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#aaa',
                    },
                  },
                  '未提交'
                ),
              ])
            } else if (params.row.auditResult == 2) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#f90',
                    },
                  },
                  '审核中'
                ),
              ])
            } else if (params.row.auditResult == 3) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#ed4014',
                    },
                  },
                  '未通过'
                ),
              ])
            } else if (params.row.auditResult == 4) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#aaa',
                    },
                  },
                  '已失效'
                ),
              ])
            } else if (params.row.auditResult == 5) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#19be1c',
                    },
                  },
                  '已通过'
                ),
              ])
            }
          },
        },
        { title: '审核意见', key: 'auditView', align: 'center' },
      ],
      breakRuleVehData: [],
    }
  },
  created() {
    this.getAppealDetailData()
  },
  methods: {
    getAppealDetailData() {
      let params = {
        appKey: this.userInfo.appKey,
        appealId: this.params.targetRow.appealId,
      }
      axios({
        method: 'post',
        url: `${WEB_CONFIG.BASE_IP}/govern-web/appeal/appealDetail`,
        data: params,
      })
        .then(res => {
          if (res.data.code === 200) {
            this.allData = res.data.data
            this.initStep()
            // 进度条
            if (this.allData.status == 3) {
              // 申诉未通过
              this.stepStatus = 'error'
              this.currentStep = 3
              this.tempComplaintStatus.push({label: '申诉未通过', value: 3, type: 1})
            } else if (this.allData.status == 4) {
              // 申诉已失效
              this.stepStatus = 'error'
              this.currentStep = 3
              this.tempComplaintStatus.push({label: '申诉已失效', value: 3, type: 1})
            } else {
              this.stepStatus = 'process'
              this.currentStep = this.allData.status
              this.tempComplaintStatus.push({label: '申诉已通过', value: 3, type: 2})
            }

            this.breakRuleVehData = res.data.data.auditRecordList
            let breakRuleData = null
            breakRuleData = res.data.data.appealDataList
            this.detailData = breakRuleData
            this.alarmTableColmuns.forEach(item => {
              breakRuleData.forEach(aItem => {
                if (aItem.alarmType === item.id) {
                  aItem.alarmIdList = aItem.alarmDataList
                  item.data = aItem.alarmDataList
                  item.alarmType = aItem.alarmType
                  this.$set(item, 'appealDesc', aItem.appealDesc)
                  // item.appealDesc = aItem.appealDesc
                  item.alarmTypeRe = aItem.alarmTypeRe
                }
              })
            })
          } else {
            this.$Message.warning('查看详情数据失败')
          }
        })
        .catch(error => {
          if (!this.checkPrivilege(error)) {
            this.$Message.warning('查看详情数据失败！请稍后再试')
          }
        })
    },
    // 初始化进度条
    initStep () {
      this.tempComplaintStatus = this.appealStatus.slice(0, 2)
    },
    // 预览文件
    previewFile(item) {
      let fileName =(Math.random() * 10000000).toString(16).substr(0, 4) + (new Date()).getTime()+item.fileName
      window.open(`http://kkfileview.dsf360.com/onlinePreview?url=`+encodeURIComponent(Base64.encode(item.url+'&fullfilename='+fileName)))
    },
    // 下载文件
    downLoadFile(url) {
      window.location.href=url
    },
    repeal() {
      this.$Modal.confirm({
        title: '撤销',
        content: '撤销操作会删除申诉单内容,是否继续？',
        onOk: async () => {
      let params = {
        appKey: this.userInfo.appKey,
        appealId: this.params.targetRow.appealId,
      }
      axios({
        method: 'post',
        url: `${WEB_CONFIG.BASE_IP}/govern-web/appeal/deleteAppeal`,
        data: params,
      })
        .then(res => {
          if (res.data.code === 200) {
            this.$Message.success('已撤销')
            this.goBack()
          } else {
            this.$Message.warning(res.data.msg)
          }
        })
        .catch(error => {
          if (!this.checkPrivilege(error)) {
            this.$Message.warning('撤销失败，请稍后再试')
          }
        })
        },
        onCancel: () => {}
      })
    },

    goBack () {
      this.$emit('goBack', this.params.backComponentName)
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-detail-page();
.complaint-detail {
  position: relative;
  height: 100%;
  width: 100%;
  .content-title {
    display: flex;
    flex-direction: row;
  }
  .page-content {
    .govern-content {
      .btn-span {
        margin-right: 10px;
      }
      .file-stys {
      width:100%;
      display:flex;
      flex-direction:row;
      flex-wrap:wrap;
      .file-sty{
        width:500px;
      }
    }
    }
  }
}
</style>

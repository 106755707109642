<template>
  <div class="complaint common-page">
    <template v-if="componentName == 'default'">
      <header class="page-header">
        <div class="header-left">
          <div class="title-instruction"></div>
          <span class="header-title">申诉</span>
        </div>
        <div class="header-right"></div>
      </header>
      <section class="page-content">
        <div class="search-wrapper">
          <div class="search-title">申诉筛选</div>
          <Divider class="divider-wrapper" size="small" />
          <div class="search-content">
            <div class="search-item">
              <RadioText
                @changeRadio="complaintStatusChange"
                :radio="complaintStatus"
                :defaultRadio="searchForm.complaintState"
                title="申诉状态:"
                labelWidth="72"
              ></RadioText>
            </div>
            <div class="search-item">
              <Form :model="searchForm" :label-width="0" inline>
                <FormItem label="企业名称:" :label-width="72">
                  <AutoComplete v-model="searchForm.unitName" @on-focus="unitSearch" @on-search="unitSearch" @on-select="unitSelect" @on-clear="clearAutoComplete" :clearable="true" placeholder="请选择企业" style="width:200px">
                    <Option v-for="item in unitArray" :value="`${item.unitName}|${item.unitId}`" :key="item.unitId">{{ item.unitName }}</Option>
                  </AutoComplete>
                </FormItem>
                <FormItem label="车牌号:" :label-width="72">
                  <Input v-model="searchForm.licensePlat" placeholder="车牌号 快速查询"/>
                </FormItem>
                <FormItem label="申诉单号:" :label-width="72">
                  <Input v-model="searchForm.vehicleNo" placeholder="申诉单号" />
                </FormItem>
                <FormItem label="提交时间:" :label-width="72">
                  <Date-picker v-model="searchForm.searchTime" format="yyyy/MM/dd" type="daterange" placeholder="选择日期" style="width: 300px;"></Date-picker>
                </FormItem>
                <FormItem>
                  <Button v-if="btnPrivilegeCode.complaint.includes('read')" class="form-btn" type="primary" @click="getTotalData('type')" :loading="searchLoading">查询</Button>
                  <!-- <Button class="form-btn" type="primary" @click="addComplaint" style="margin-left:5px" icon="md-add">添加申诉</Button> -->
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <Spin size="large" fix v-if="searchLoading"></Spin>
          <Table :columns="tableColmuns" :data="tableData" size="small">
            <template slot-scope="{ row }" slot="vehicle">
              {{`${row.vehicleNo}(${row.vehiclePlateColor})`}}
            </template>
            <template slot-scope="{ row }" slot="action">
              <span v-if="btnPrivilegeCode.complaint.includes('Details')" class="btn-span" @click="openDetail(row)">查看</span>
            </template>
          </Table>
          <div class="pagenation-wrapper">
            <Page
              :current="paginationConfig.currentPage"
              :page-size="paginationConfig.pageSize"
              :total="paginationConfig.total"
              @on-change="currentPageChange"
              show-elevator
              show-total
              size="small"
            />
          </div>
        </div>
      </section>
    </template>
    <div v-else-if="componentName == 'ComplaintDetail'">
      <ComplaintDetail :params="{targetRow: clickRow, backTitle: '申诉', backComponentName: 'default'}" @goBack="changeComponentName"></ComplaintDetail>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { getTimeStrByType } from '@/assets/js/common.js'
import { btnPrivilegeMixin, unitSearchMixin, paginationMixin, privilegeMixin, paramsMixin, pageChangeMixin, userInfoMixin } from '@/assets/js/mixin.js'
import ComplaintDetail from './complaint_detail/index.vue'
import RadioText from '@/components/radio-text/index.vue'
export default {
  mixins: [btnPrivilegeMixin, unitSearchMixin, paginationMixin, privilegeMixin, paramsMixin, pageChangeMixin, userInfoMixin],
  name: 'complaint',
  components: {
    RadioText,
    ComplaintDetail
  },
  data() {
    return {
      searchForm: {
        unitName: '',
        unitId: '',
        complaintState: [0],
        filterVehicle: '',
        searchTime: ['', ''],
        vehicleNo: '',
      },
      searchLoading: false,
      tableColmuns: [
        {type: 'index', title: '序号', width: 60, align: 'center'},
        { title: '申诉单', key: 'appealId', align: 'center' },
        {title: '企业名称', key: 'unitName', align: 'center'},
        {
          title: '申诉状态',
          key: 'status',
          align: 'center',
          render: (h, params) => {
            if (params.row.status == 1) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#aaa',
                    },
                  },
                  '未提交'
                ),
              ])
            } else if (params.row.status == 2) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#f90',
                    },
                  },
                  '审核中'
                ),
              ])
            } else if (params.row.status == 3) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#ed4014',
                    },
                  },
                  '未通过'
                ),
              ])
            } else if (params.row.status == 4) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#aaa',
                    },
                  },
                  '已失效'
                ),
              ])
            } else if (params.row.status == 5) {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color: '#19be1c',
                    },
                  },
                  '已通过'
                ),
              ])
            }
          },
        },
        { title: '申诉车辆',  align: 'center',slot: 'vehicle',},
        {
          title: '申诉提交时间',
          key: 'appealTime',
          align: 'center',
          render: (h, params) => {
            if (params.row.appealTime) {
              return h('span', params.row.appealTime)
            } else {
              return h('span', '--')
            }
          },
        },
        { title: '操作', slot: 'action', align: 'center' },
      ],
      clickRow: null,
      tableData: [],
    }
  },
  computed: {
    complaintStatus() {
      return [{ label: '全部', value: 0 }, ...this.appealStatus]
    },
  },
  created () {
    this.getCurrentWeek()
  },
  methods: {
    // 获取数据
    getTotalData(type) {
      if (type == 'search') {
        this.paginationConfig.currentPage = 1
      }
      this.searchLoading = true
      let params = {
        appKey: this.userInfo.appKey,
        unitId: this.searchForm.unitId ? this.searchForm.unitId : 0,
        appealId: this.searchForm.vehicleNo,
        status: this.searchForm.complaintState[0],
        licensePlat: this.searchForm.licensePlat,
        pageSize: this.paginationConfig.pageSize,
        pageIndex: this.paginationConfig.currentPage,
      }
      if (this.searchForm.searchTime[0] != '' || this.searchForm.searchTime[1] != '') {
        params.beginDate = getTimeStrByType(this.searchForm.searchTime[0], 3)
        params.endDate = getTimeStrByType(this.searchForm.searchTime[1], 3)
      } else {
        params.beginDate = ''
        params.endDate = ''
      }
      axios({
        method: 'post',
        url: `${WEB_CONFIG.BASE_IP}/govern-web/appeal/pageList`,
        data: params,
      })
        .then(res => {
          this.searchLoading = false
          this.isSearched = true
          if (res.data.code === 200) {
            this.tableData = res.data.data.records
            this.paginationConfig.total = res.data.data.total
          } else {
            this.$Message.warning(res.data.msg)
          }
        })
        .catch(error => {
          this.searchLoading = false
          if (!this.checkPrivilege(error)) {
            this.$Message.error('获取申诉信息失败, 请稍后重试!')
          }
        })
    },
    // 申诉查询状态改变
    complaintStatusChange(target) {
      this.searchForm.complaintState = target
    },
    // 违章类型改变
    alarmTypeChange(target) {
      this.alarmType = target
    },
    openDetail(row) {
      row['beginTime'] = row.noticeBeginTime
      row['endTime'] = row.noticeEndTime
      this.clickRow = row
      this.componentName = 'ComplaintDetail'
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-table-page();
.complaint {
  .page-content {
    .search-wrapper {
      padding-left: 8px;
      .search-content {
        .search-item {
          margin: 10px 0;
          .form-btn {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
